const theme1 = {
    '--red': '#e06c76', // Red Colour
    '--green': '#98c379', // Green Colour
    '--yellow': '#e5c07b', // Yellow Colour
    '--blue': '#61afef', // Blue Colour
    '--magenta': '#c678dd', // Magenta Colour
    '--cyan': '#56b6c2', // Cyan Colour

    '--background-color': '#181818', // Background Colour
    '--secondary-background-color': '#21252b', // Background Colour
    '--card-color': '#23272e', // Card Colour
    '--primary-color': '#323842', // Primary Colour
    // '--primary-color': 'var(--cyan)', // Primary Colour
    '--accent-color': 'var(--red)', // Secondary Colour
    '--text-color': '#ffffff',
    '--accent-gradient': "linear-gradient(111.4deg, var(--red) 6.5%, var(--red) 93.2%)",
} as React.CSSProperties


export default theme1;