import React from 'react';

import './DiagonalImageCard.css';
import { Interface } from 'readline';

export enum Corner {
    TopLeft,
    TopRight,
    BottomLeft,
    BottomRight
}

interface DiagonalImageCardProps {
    image: string;
    imagecorner: Corner;
    className?: string;
    children?: React.ReactNode;
}

function DiagonalImageCard(props: DiagonalImageCardProps) {

    const imageContainer = (
        <div className={"diagonal-image-container rounded-border" + " corner_" + props.imagecorner} >
            <img className="img-fluid rounded-border" src={props.image} alt="diagonal-image-card" />
        </div>
    );

    const contentContainer = (
        <div className={"diagonal-image-card-content rounded-border text-color drop-shadow p-4" + " corner_" + props.imagecorner + " " + props.className}>
            {props.children}
        </div>
    );

    // const top: React.ReactNode = props.imagecorner === Corner.TopLeft || props.imagecorner === Corner.TopRight ? imageContainer : contentContainer;
    // const bottom: React.ReactNode = props.imagecorner === Corner.BottomLeft || props.imagecorner === Corner.BottomRight ? imageContainer : contentContainer;

    return (
        <div className="diagonal-image-card">
            {/* {top} */}
            {/* {bottom} */}
            {imageContainer}
            {contentContainer}
        </div>
    );
}

export default DiagonalImageCard;