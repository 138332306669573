import React from 'react';
import './ResearchCard.css';


interface ResearchCardProps {
    title: string;
    description: string;
    link: string;
    award?: string;
    toptitle?: string;
}

function ResearchCard(props: ResearchCardProps) {
    return (
        <div className="research-card primary-color text-color rounded-border drop-shadow p-3">
            <p> {props.toptitle} </p>
            <h4> <a href={props.link} className="research-title text-color" target='_'> {props.title} </a> </h4>
            <hr />
            <p> {props.description} </p>
            {
                (props.award) ? (
                    <div>
                        <hr />
                        <p> ★ {props.award} </p>
                    </div>
                ) : (null)
            }
        </div>
    )
}

export default ResearchCard;