
import React from 'react';
import './TimelineIcon.css';


interface TimelineIconProps {
    icon: string;
}

function TimelineIcon(props: TimelineIconProps) {
    return (
        <div className="h-100">
            <img className="timeline-icon mx-auto my-auto" src={props.icon} />
        </div>
    );
}

export default TimelineIcon;