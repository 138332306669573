import React from 'react';
import './SmallButton.css';

interface SmallButtonProps {
    icon?: string;
    href?: string;
}

function SmallButton(props: SmallButtonProps) {

    return (
        <a className="small-button primary-color m-auto drop-shadow-small" href={props.href ? props.href : '#'}>
            <img className="small-button-icon" src={props.icon} />
        </a>
    );
}

export default SmallButton;