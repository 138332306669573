import React from 'react';
import AboutPage from './AboutPage';
import ExperiencePage from './ExperiencePage';
import ResearchPage from './ResearchPage';
import ProjectsPage from './ProjectsPage';
import { Routes, Route } from "react-router-dom";

import { Outlet } from "react-router-dom";

function PageContainer() {

    return (
        <div className="card-color rounded-border drop-shadow p-4 text-color">

            <Outlet />
            <Routes>
                <Route path="/">
                    <Route index element={<AboutPage />} />
                    <Route index path="about" element={<AboutPage />} />
                    <Route path="experience" element={<ExperiencePage />} />
                    <Route path="research" element={<ResearchPage />} />
                    <Route path="projects" element={<ProjectsPage />} />
                    <Route path="*" element={<AboutPage />} />
                </Route>
            </Routes>
        </div>
    );
}

export default PageContainer;