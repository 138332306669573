import React from 'react';
import './MenuButton.css';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"


interface MenuButtonProps {
    icon?: string;
    text?: string;
    link?: string;
}

function MenuButton(props: MenuButtonProps) {

    const location = useLocation();
    const selected = location.pathname === "/" + props.link;

    return (
        <Link className={"menu-button primary-color mx-2 drop-shadow-small" + (selected ? " menu-button-selected" : "")}
            to={props.link ? props.link : "about"}>
            <img className="menu-button-icon" src={props.icon} />
            <div className="text-color text-center">
                {props.text}
            </div>
        </Link>

    );
}

export default MenuButton;