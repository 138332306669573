import React from "react";

import "./Page.css"

interface PageTitleProps {
    title: string;
}

function PageTitle(props: PageTitleProps) {
    return (
        <div>
            <h1 className='text-color pb-3'>{props.title}</h1>
            {/* <hr className="w-100 hr-border-white pb-3" /> */}
            <div className="div-line mb-3"></div>
        </div>
    )
}

export default PageTitle;