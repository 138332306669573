import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/esm/Container';
import SideCard from './components/side-card/SideCard';
// import logo from './logo.svg';
import './App.css';
import MenuBar from './components/menu-bar/MenuBar';
import PageContainer from './components/pages/PageContainer';
import { BrowserRouter } from 'react-router-dom';
import theme1 from './themes/theme1';
import theme2 from './themes/theme2';
import theme3 from './themes/theme3';
import ThemeSelector from './components/theme-selector/ThemeSelector';


function App() {

    // State for theme selection
    const [theme, setTheme] = React.useState(theme1);
    const themes = [theme1, theme2, theme3]

    return (
        <div style={theme} className="app-div">

            <div className="background-div"> </div>
            {/* <ThemeSelector theme={theme} setTheme={setTheme} themes={themes} /> */}
            <BrowserRouter>
                {/* <Container fluid className="mt-5 w-75"> */}
                <Container className="mt-0 pt-5 mb-5">
                    <Row>
                        {/* <Col xl={4} lg={4} md={5} sm={3} xs={2}> */}
                        <Col className="order-lg-last mb-4" xl={4} lg={5} md={12}>
                            <div className='fixed-col' >
                                <SideCard />
                            </div>
                        </Col>
                        <Col xl={8} lg={7} md={12}>
                            <Row className="mb-4">
                                <Col xl={6} lg={8}><MenuBar /></Col>
                                <Col xl={6} lg={4}></Col>
                            </Row>

                            <Row className="">
                                <Col><PageContainer /></Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </BrowserRouter >
        </div >
    );
}

export default App;
