import React from 'react';
import PageTitle from './PageTitle';
import ResearchCard from '../research-card/ResearchCard';

function ResearchPage() {
    return (
        <div>
            <PageTitle title="Research" />

            <ResearchCard
                toptitle="June, 2023 • MEng Project • Graded 93%"
                title="Arguing with physical activity data from a real-world wearable clinical trial with patients with a primary brain tumour"
                description="The objective of this project is to leverage Abstract Argumentation for Case-Based Reasoning (AA-CBR) to support clinical decision-making. The BrainWear study aims to assess the feasibility of its innovative use of wearable accelerometers to capture real-time Physical Activity (PA) data over intermittently collected Patient Reported Outcome (PRO) measures, for the assessment of patient Health-Related Quality of Life (HRQoL). This project proposes novel uses of AA-CBR as a transparent machine learning model to assess the utility of PA data from the BrainWear study, focusing on a patient-centred and transparent approach."
                link="MEngReport.pdf"
                award="The Winton Capital Applied Undergraduate Project Computing Prize"
            />


        </div>
    );
}

export default ResearchPage;