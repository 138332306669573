import React from 'react';
import PageTitle from './PageTitle';

import "./Page.css"

function AboutPage() {
    return (
        <div className="text-color">
            <PageTitle title="About Me" />
            <div className="text-left">
                Hello! I'm Adam, and I'm Computing Student. My academic foundation was built at Imperial College London, where I pursued an MEng in Computer Science, specializing in the captivating realms of AI and Machine Learning. My journey has led me to dive into the captivating world of Explainable AI. I'm particularly intrigued by its potential in healthcare, as showcased in my research on wearables for brain tumor patients. Outside of academia, I'm an 3D printing enthusiast, a bookworm, an avid boulderer, a gamer, and an appreciator of quality TV shows.
            </div>
            {/* <img className="hobby-icon" src='3d-printer.png' />
            <img className="hobby-icon" src='bouldering.png' />
            <img className="hobby-icon" src='book.png' />
            <img className="hobby-icon" src='tv.png' />
            <img className="hobby-icon" src='switch.png' /> */}
        </div>
    );
}

export default AboutPage;