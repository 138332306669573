import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MenuButton from '../menu-button/MenuButton';

import './MenuBar.css';

enum Pages {
    ABOUT = "about",
    EXPERIENCE = "experience",
    PROJECTS = "projects",
    RESEARCH = "research"
}

function makeState(page: Pages) {
    return {
        page: page
    }
}


function MenuBar() {
    return (
        <div className="menubar-container drop-shadow">
            <div className="card-color rounded-border menubar text-center">
                <div className="menu-content-container px-3">
                    <Row className="m-auto h-100">
                        <Col className="px-0 h-100"><MenuButton text="About" icon="iconmonstr-user-1.svg" link={"about"} /></Col>
                        <Col className="px-0 h-100"><MenuButton text="Work" icon="iconmonstr-briefcase-1.svg" link={"experience"} /></Col>
                        <Col className="px-0 h-100"><MenuButton text="Projects" icon="iconmonstr-folder-20.svg" link={"projects"} /></Col>
                        <Col className="px-0 h-100"><MenuButton text="Research" icon="iconmonstr-magnifier-4.svg" link={"research"} /></Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default MenuBar;