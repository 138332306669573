import React from 'react';
import TimelineIcon from '../timeline-icon/TimelineIcon';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import PageTitle from './PageTitle';

function ExperiencePage() {
    return (
        <div>
            <PageTitle title="Experience" />
            <VerticalTimeline className="w-100">
                {/* <VerticalTimelineElement
                    className=""
                    contentStyle={{ backgroundImage: 'var(--accent-gradient)', color: 'var(--text-color)', textAlign: 'left' }}
                    contentArrowStyle={{ borderRight: '7px solid  var(--blue)' }}
                    date="October 2023 - present"
                    iconStyle={{ background: 'var(--red)', color: '#fff' }}
                    icon={<TimelineIcon icon="iconmonstr-briefcase-1.svg" />}
                >
                    <h4> Current Role </h4>
                    <h5> Location </h5>
                    <h6> Title  </h6>
                    <p>
                        TODO: Add description
                    </p>
                </VerticalTimelineElement> */}
                <VerticalTimelineElement
                    className=""
                    date="October 2023 - June 2023"
                    contentArrowStyle={{ borderRight: '7px solid  var(--primary-color)' }}
                    contentStyle={{ background: 'var(--primary-color)', color: 'var(--text-color)', textAlign: 'left' }}
                    iconStyle={{ background: 'var(--green)', color: '#fff' }}
                    icon={<TimelineIcon icon="iconmonstr-school-21.svg" />}
                >
                    <h4> Masters Dissertation </h4>
                    <h5> Imperial College London </h5>
                    <h6> Arguing with physical activity data from a real-world wearable clinical trial with patients with a primary brain tumour  </h6>

                    <ul>
                        <li> Achieved a grade of 93% </li>
                        <li> Awarded The Winton Capital Applied Undergraduate Project Computing Prize </li>
                    </ul>

                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className=""
                    date="April 2022 - September 2022"
                    contentArrowStyle={{ borderRight: '7px solid  var(--primary-color)' }}
                    contentStyle={{ background: 'var(--primary-color)', color: 'var(--text-color)', textAlign: 'left' }}
                    iconStyle={{ background: 'var(--blue)', color: '#fff' }}
                    icon={<TimelineIcon icon="iconmonstr-briefcase-1.svg" />}
                >
                    <h4> Morgan Stanley </h4>
                    <h5> Technology Industrial Placement </h5>
                    <ul>
                        <li> Working in Equity Swaps division </li>
                        <li> Full-stack development, with Angular and Java Spring framework </li>
                        <li> Worked through development and deployment cycle including configuring automated pipelines with Jenkins, using modern testing frameworks, like Cypress, configuring web servers and setting up authentication </li>
                    </ul>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className=""
                    date="September 2021 - March 2022"
                    contentArrowStyle={{ borderRight: '7px solid  var(--primary-color)' }}
                    contentStyle={{ background: 'var(--primary-color)', color: 'var(--text-color)', textAlign: 'left' }}
                    iconStyle={{ background: 'var(--blue)', color: '#fff' }}
                    icon={<TimelineIcon icon="iconmonstr-briefcase-1.svg" />}
                >
                    <h4> Imperial College London </h4>
                    <h5> Undergraduate Teaching Assistant </h5>
                    <ul>
                        <li> Organised and ran weekly tutorials for first year students </li>
                        <li> Teaching the fundamental concepts of functional and object oriented programming in Haskell, Kotlin and Java </li>
                        <li> Marked programming exercises, provided feedback on work and graded code </li>
                    </ul>
                </VerticalTimelineElement>



                <VerticalTimelineElement
                    className=""
                    date="June 2021 - August 2021"
                    contentArrowStyle={{ borderRight: '7px solid  var(--primary-color)' }}
                    contentStyle={{ background: 'var(--primary-color)', color: 'var(--text-color)', textAlign: 'left' }}
                    iconStyle={{ background: 'var(--blue)', color: '#fff' }}
                    icon={<TimelineIcon icon="iconmonstr-briefcase-1.svg" />}
                >
                    <h4> Morgan Stanley </h4>
                    <h5> Summer Technology Analyst </h5>
                    <ul>
                        <li> Summer Programming Internship working in Equity Swaps division </li>
                        <li> Used Angular to design a front-end status dashboard </li>
                        <li> Created a RESTful back-end using the Java Spring framework </li>
                    </ul>
                </VerticalTimelineElement>



                <VerticalTimelineElement
                    className=""
                    date="October 2019 - June 2023"
                    contentArrowStyle={{ borderRight: '7px solid  var(--primary-color)' }}
                    contentStyle={{ background: 'var(--primary-color)', color: 'var(--text-color)', textAlign: 'left' }}
                    iconStyle={{ background: 'var(--green)', color: '#fff' }}
                    icon={<TimelineIcon icon="iconmonstr-school-21.svg" />}
                >
                    <h4> Computing Integrated Masters (MEng)  </h4>
                    <h5> Imperial College London</h5>
                    <h6> AI and Machine Learning </h6>
                    <table className="table table-striped table-dark">
                        <thead>
                            <tr>
                                <th scope="col">Year</th>
                                <th scope="col">Grade</th>
                                <th scope="col">Dean's List Award</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>First</td>
                                <td>75%</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Second</td>
                                <td>82%</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Third</td>
                                <td>81%</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Fourth</td>
                                <td>85%</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Final Grade</td>
                                <td>83%</td>
                                <td>First Class Honours</td>
                            </tr>
                        </tbody>
                    </table>
                    <ul>
                        <li> Dean's List awarded for achieving grades within top 10% of the cohort </li>
                    </ul>


                </VerticalTimelineElement>


                <VerticalTimelineElement
                    className=""
                    date="September 2011 - June 2018"
                    contentArrowStyle={{ borderRight: '7px solid  var(--primary-color)' }}
                    contentStyle={{ background: 'var(--primary-color)', color: 'var(--text-color)', textAlign: 'left' }}
                    iconStyle={{ background: 'var(--green)', color: '#fff' }}
                    icon={<TimelineIcon icon="iconmonstr-school-21.svg" />}
                >
                    <h4>GCSE/A Level</h4>
                    <h5>JCoSS</h5>
                    <ul>
                        <li>A Levels - Maths (A*), Further Maths (A*), Computer Science (A*), Physics (A*)</li>
                        <li>GCSEs - 10 A* and a A including Maths, Further Maths, English and Computer Science </li>
                    </ul>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    iconStyle={{ background: 'var(--yellow)', color: '#fff' }}
                    icon={<TimelineIcon icon="iconmonstr-star-7.svg" />}
                />
            </VerticalTimeline>
        </div>
    );
}

export default ExperiencePage;