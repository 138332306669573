import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SmallButton from '../small-button/SmallButton';

import './SideCard.css';

function SideCard() {

    return (
        < div className="card-color rounded-border sidecard text-center drop-shadow" >
            <div className="image-container m-auto">
                <img className="sidecard-mascot m-auto h-100 drop-shadow-small" src="pug.png" />
                <br />
                <img className="sidecard-image rounded-border m-auto h-100 drop-shadow-small" src="adamg.jpg" />
            </div>
            <div className="sidecard-content-container">
                <h2 className="text-color">Adam Gould</h2>
                <div className="primary-color rounded-border p-0 w-50 m-auto drop-shadow-small">
                    <p className="text-color px-0 py-1">PhD Candidate</p>
                </div>
                <div className="w-50 mx-auto">
                    <div className="div-line mb-3"></div>
                </div>
                <Row className="m-auto p-0 w-50">
                    <Col className="px-0"><SmallButton icon="iconmonstr-linkedin-3.svg" href="https://www.linkedin.com/in/adamg39/" /></Col>
                    <Col className="px-0"><SmallButton icon="iconmonstr-cv-1.svg" href="/Adam Gould CV.pdf" /></Col>
                    <Col className="px-0"><SmallButton icon="iconmonstr-github-3.svg" href="https://github.com/TheAdamG" /></Col>
                    <Col className="px-0"><SmallButton icon="iconmonstr-email-3.svg" href="mailto:adam.gould39@gmail.com" /></Col>
                </Row>
            </div>
            {/* <div className="sidecard-bottom-container primary-color rounded-border mt-2 m-auto w-75 text-color">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div> */}
        </div >
    );
}

export default SideCard;