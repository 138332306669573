import React from 'react';
import DiagonalImageCard from '../diagonal-image-card/DiagonalImageCard';
import { Corner } from '../diagonal-image-card/DiagonalImageCard';
import PageTitle from './PageTitle';

function ProjectsPage() {
    return (
        <div>
            <PageTitle title="Projects" />

            <DiagonalImageCard image="daedalus.png" imagecorner={Corner.TopLeft} className='background-red'>
                <h4 className="text-color">Daedalus</h4>
                <p className="text-color">Created a web-based tool to process and visualize 3D terrain data in both standard and augmented reality (AR) environments, utilizing React, NPM libraries, and Python Flask for development.</p>
            </DiagonalImageCard>

            <DiagonalImageCard image="protectly.png" imagecorner={Corner.TopRight} className="background-magenta">
                <h4 className="text-color">Protectly</h4>
                <p className="text-color">Designed and developed a night safety application for Android using Flutter and Dart, recognized as one of the top "Designing for Real People" projects in 2021 at Imperial College London.</p>
            </DiagonalImageCard>

            <DiagonalImageCard image="pharmasafe.png" imagecorner={Corner.TopLeft} className="background-blue">
                <h4 className="text-color">PharmaSafe</h4>
                <p className="text-color">Designed and launched an award-winning website enabling users to effortlessly verify potential medication interactions, earning the prestigious NHSX award at Imperial College London's health-focused Hackathon.</p>
            </DiagonalImageCard>

            <DiagonalImageCard image="NightSafe.png" imagecorner={Corner.TopRight} className='background-green'>
                <h4 className="text-color">NightSafe</h4>
                <p className="text-color">Developed a Java-based Android application aimed at enhancing group safety during night outings, securing the Most Helpful Hack award from Nextjump at IC Hack 2020, the UK's largest student-run Hackathon.</p>
            </DiagonalImageCard>


        </div>
    );
}

export default ProjectsPage;